import React from 'react'
import { useAdmin } from '../../Hooks/useAdmin'

//const admin = useAdmin()

export const GridContent = ({ children, layout, mode, id }) => {
  return (
    <div className={`container ${mode}`} id={id}>
      <div className={`grid-column-12 ${layout}`}>{children}</div>
    </div>
  )
}
