import React, { useRef, useState, useEffect } from 'react'
import { Mute, Pause, Play, UnMute } from '../../graphics/video-controls'

import { MainLogo } from '../../graphics/logo'
import { useInView } from 'react-spring'

export const AboveTheFold = ({ files }) => {
  const [playing, isPlaying] = useState(true)
  const [mute, isMute] = useState(true)
  const [containerRef, isInView] = useInView({})
  const videoRef = useRef(null)
  const logoRef = useRef(null)

  // Set the video height to 16:9
  useEffect(() => {
    containerRef.current.style.height = window.innerWidth / 1.77777 + 'px'
  }, [])

  useEffect(() => {
    if (videoRef.current)
      isInView ? videoRef.current.play() : videoRef.current.pause()
  }, [isInView])

  useEffect(() => {
    if (document.readyState !== 'loading') {
      videoRef.current.classList.add('reveal')
      setTimeout(() => {
        if (logoRef.current) logoRef.current.classList.add('hide')
      }, 4000)
    }

    return () => clearTimeout()
  }, [videoRef.current, logoRef.current])

  function handlePlay() {
    playing ? videoRef.current.pause() : videoRef.current.play()
    isPlaying(!playing)
  }

  const location = () => {
    //make sure we aren't SSR...
    let elm
    if (typeof window !== 'undefined') {
      const path = window.location.pathname.replace(/^\/|\/$/g, '')
      elm = path === '' ? 'home' : path
    }

    return elm
  }

  return (
    <div
      className={`video-container ${location()}`}
      id="atf"
      ref={containerRef}
    >
      <video
        ref={videoRef}
        autoPlay
        loop
        preload="none"
        muted={mute}
        playsInline
        style={{
          width: '100vw',
          height: 'auto',
          position: 'relative',
          opacity: 0,
          transition: 'opacity 2s',
        }}
      >
        <source src={files.mp.publicURL} type="video/mp4" />
        <source src={files.webm.publicURL} type="video/webm" />
        Your browser does not support the video tag.
      </video>
      <div className="video-controls">
        <button role="button" aria-label="Play/Pause" onClick={handlePlay}>
          {playing ? <Pause /> : <Play />}
        </button>
        <button
          role="button"
          aria-label="Mute/UnMute"
          onClick={() => isMute(!mute)}
        >
          {mute ? <UnMute /> : <Mute />}
        </button>
      </div>

      <div
        ref={logoRef}
        className="main-logo"
        style={{
          position: 'absolute',
          width: '40%',
          inset: '40px',
          margin: 'auto',
        }}
      >
        <MainLogo />
      </div>
    </div>
  )
}
