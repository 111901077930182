import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { BgImage } from 'gbimage-bridge'
import { links } from './nav'
import { useInView, useTrail, animated } from 'react-spring'

const colors = [
  '--green-brunswick',
  '--green-lambay-fern',
  '--green-michael-d',
  '--green-brightwater',
  '--green-amabels',
  '--green-spring-stone',
]

const MaxiMenu = () => {
  const [ref, isInView] = useInView({})
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { name: { eq: "footer" } }) {
        edges {
          node {
            childMarkdownRemark {
              frontmatter {
                maxi {
                  image {
                    childImageSharp {
                      gatsbyImageData(
                        width: 820
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const image = getImage(
    data.allFile.edges[0].node.childMarkdownRemark.frontmatter.maxi.image
  )

  const items = links()

  const trail = useTrail(items.length, {
    config: { mass: 5, tension: 2000, friction: 200 },

    left: isInView ? '0%' : '-220%',
  })

  return (
    <BgImage image={image} className="maxi-container">
      <div className="menu-parent">
        <div className="menu" ref={ref}>
          {trail.map(({ left, ...style }, index) => {
            return (
              <animated.div
                className="transform-weight"
                style={{ left }}
                key={items[index].name}
              >
                <Link
                  to={items[index].link}
                  className="maxi-link"
                  style={{ backgroundColor: `var(${colors[index]})` }}
                >
                  <h2>{items[index].name}</h2>
                </Link>
              </animated.div>
            )
          })}
        </div>
      </div>
    </BgImage>
  )
}

export default MaxiMenu
