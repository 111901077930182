import React from 'react'

export const Play = () => {
  return (
    <svg
      width="19"
      height="28"
      viewBox="0 0 19 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4374 14.25L3.4375 21.0775V7.42248L12.4374 14.25Z"
        stroke="white"
        strokeWidth="6.875"
        opacity="0.5"
      />
    </svg>
  )
}

export const Pause = () => {
  return (
    <svg
      width="26"
      height="28"
      viewBox="0 0 26 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 0.5V28M22.125 0.5V28"
        stroke="white"
        strokeWidth="6.875"
        opacity="0.5"
      />
    </svg>
  )
}

export const Mute = () => {
  return (
    <svg
      width="23"
      height="31"
      viewBox="0 0 23 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.0695801 7.25313H6.52971V22.9191H0.0695801V7.25313Z"
        opacity="0.5"
        fill="white"
      />
      <path
        d="M8.30614 7.25313C8.30614 7.25313 15.0086 2.6521 18.8848 0.0668945C23.9721 9.11226 23.8914 20.9828 18.8848 30.9143C14.9279 27.6852 8.30614 22.9191 8.30614 22.9191V7.25313Z"
        opacity="0.5"
        fill="white"
      />
    </svg>
  )
}

export const UnMute = () => {
  return (
    <svg
      width="24"
      height="33"
      viewBox="0 0 24 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.0185547 29.8279L18.0245 0.465332L23.1548 3.61135L5.14882 32.9739L0.0185547 29.8279Z"
        fill="white"
      />
      <g opacity="0.5">
        <path
          d="M11.134 26.6264C13.4225 28.3075 16.9107 30.9078 19.3696 32.9145C23.2125 25.2914 24.1533 16.526 22.0825 8.7726L11.134 26.6264Z"
          fill="white"
        />
        <path
          d="M11.7894 7.20117C10.0486 8.39001 8.791 9.25334 8.791 9.25334V12.0907L11.7894 7.20117Z"
          fill="white"
        />
        <path
          d="M7.01457 14.9876V9.25334H0.554443V24.9193H0.924136L7.01457 14.9876Z"
          fill="white"
        />
      </g>
    </svg>
  )
}

export const Fullscreen = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      className="fullscreen-button"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 10.5V5.5H10.5V0.5H5.5H0.5V5.5V10.5H5.5ZM5.5 18H0.5V23V28H5.5H10.5V23H5.5V18ZM18 23V28H23H28V23V18H23V23H18ZM23 10.5H28V5.5V0.5H23H18V5.5H23V10.5Z"
        fill="var(--irbisio-drk-blue)"
        opacity="0.5"
      />
    </svg>
  )
}

export const Close = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ mixBlendMode: 'luminosity' }}
    >
      <path
        d="M30.5 3L3 30.5M3 3L30.5 30.5"
        stroke="var(--irbisio-off-white)"
        strokeWidth="6.875"
      />
    </svg>
  )
}
