import React from 'react'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { graphql } from 'gatsby'
import Layout from '../components/UI/layout/Layout'
import { GridContent } from '../components/UI/layout/grid-content'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { BgImage } from 'gbimage-bridge'
import MaxiMenu from '../components/UI/navigation/maxi-menu'
import { Link } from 'gatsby'
import { AboveTheFold } from '../components/UI/layout/landing-page'

const Home = ({ data }) => {
  const { introduction, house } =
    data.allFile.edges[0].node.childMarkdownRemark.frontmatter
  return (
    <Layout>
      <GatsbySeo title="Home Page" />
      <AboveTheFold files={introduction.videos} />
      <GridContent id="introduction" mode="white" layout=" --2-column ">
        <div>
          <div className="text-content-margin">
            <h2>{introduction.title}</h2>
            <h3>{introduction.body}</h3>
          </div>
        </div>
        <BgImage
          image={getImage(introduction.image)}
          className="image-background float-right"
          style={{
            backgroundSize: 'cover',
            backgroundPosition: 'left center',
          }}
        />
      </GridContent>
      <GridContent
        id="house-page-1"
        layout="--3-column content-margin-top content-margin-bottom"
        mode="green-mode"
      >
        <GatsbyImage
          image={getImage(house.page_1.image_left)}
          alt="background"
        />
        <div className="text-content-margin">
          {house.page_1.centre.quote && (
            <h5 className="quote">{house.page_1.centre.quote}</h5>
          )}
          <p>{house.page_1.centre.body}</p>
        </div>
        <GatsbyImage
          image={getImage(house.page_1.image_right)}
          alt="background"
        />
      </GridContent>
      <GatsbyImage
        id="full-bleed-image"
        image={getImage(house.full_image)}
        alt="background"
        style={{ maxHeight: '60vh', width: '100%' }}
      />
      <GridContent id="house-page-2" mode="white-mode" layout="--2-column">
        <BgImage
          className="image-background float-left"
          image={getImage(house.page_2.image)}
          style={{
            backgroundSize: 'cover',
            backgroundPosition: 'right center',
          }}
        />
        <p className="text-content-margin">{house.page_2.body}</p>
      </GridContent>
      <GridContent
        id="house-page-3"
        mode="white-mode"
        layout="--2-column content-margin-top content-margin-bottom"
      >
        <div className="text-content-margin">
          <p className="text-content-margin">{house.page_3.body}</p>
        </div>
        <GatsbyImage
          image={getImage(house.page_3.image_right)}
          alt="background"
        />
      </GridContent>
      <GridContent
        id="house-page-4"
        layout="--3-column content-margin-top content-margin-bottom"
        mode="green-mode"
      >
        <GatsbyImage
          image={getImage(house.page_4.image_left)}
          alt="background"
        />
        <div className="text-content-margin">
          {house.page_4.centre.quote && (
            <h5 className="quote">{house.page_4.centre.quote}</h5>
          )}
          <p>{house.page_4.centre.body}</p>
          <br />
          <br />
          <Link
            className="align-right mock-button"
            to={house.page_4.centre.button.link}
          >
            {house.page_4.centre.button.label}
          </Link>
        </div>
        <GatsbyImage
          image={getImage(house.page_4.image_right)}
          alt="background"
        />
      </GridContent>
      <MaxiMenu />
    </Layout>
  )
}

export default Home

export const query = graphql`
  query {
    allFile(filter: { name: { eq: "home" } }) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              introduction {
                body
                title
                image {
                  childImageSharp {
                    gatsbyImageData(
                      width: 820
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
                videos {
                  webm {
                    publicURL
                  }
                  mp {
                    publicURL
                  }
                }
              }
              house {
                full_image {
                  childImageSharp {
                    gatsbyImageData(
                      width: 1200
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
                page_1 {
                  centre {
                    body
                    quote
                  }
                  image_left {
                    childImageSharp {
                      gatsbyImageData(
                        width: 820
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                      )
                    }
                  }
                  image_right {
                    childImageSharp {
                      gatsbyImageData(
                        width: 820
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                      )
                    }
                  }
                }
                page_2 {
                  body
                  image {
                    childImageSharp {
                      gatsbyImageData(
                        width: 820
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                      )
                    }
                  }
                }
                page_3 {
                  body
                  image_right {
                    childImageSharp {
                      gatsbyImageData(
                        width: 820
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                      )
                    }
                  }
                }
                page_4 {
                  centre {
                    body
                    quote
                    button {
                      label
                      link
                    }
                  }
                  image_left {
                    childImageSharp {
                      gatsbyImageData(
                        width: 820
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                      )
                    }
                  }
                  image_right {
                    childImageSharp {
                      gatsbyImageData(
                        width: 820
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
